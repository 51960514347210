<template>
  <v-content>
    <router-view />
    <v-snackbar
      v-model="$store.state.app.notification.show"
      :timeout="$store.state.app.notification.timeout"
      :color="$store.state.app.notification.color"
      >{{ $store.state.app.notification.message }}</v-snackbar
    >
  </v-content>
</template>

<script>
export default {
  name: "AgencyCoreView",
};
</script>
